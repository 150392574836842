import { ReactElement, AriaAttributes, useRef, cloneElement } from 'react';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import FocusLock from 'react-focus-lock';
import { legacyZIndices } from '@ui-v2/theme/zIndices';
import useOnOutsideClick from '../../hooks/useOnOutsideClick';
import { mqMin } from '../../styles/base';
import Portal from '../Portal';

interface Props {
  ariaLabel: AriaAttributes['aria-label'];
  autoFocus?: boolean;
  beforeElementId?: string;
  children: ReactElement;
  className?: string;
  disableOutsideClick?: boolean;
  id: string;
  onClose?: () => void;
  role?: 'dialog' | 'alertdialog';
}

const fade = keyframes`
  100% {
    opacity: 1;
  }
`;

const StyledPortal = styled(Portal)`
  position: fixed;
  z-index: ${legacyZIndices.max};
  display: flex;
  align-items: center;
  justify-content: center;
  inset: 0;

  ${mqMin.Large} {
    animation: ${fade} 0.3s ease-out forwards;
    opacity: 0;
  }
`;

const Overlay = styled.div`
  position: fixed;
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(255 255 255 / 30%);
  inset: 0;
`;

const StyledFocusLock = styled(FocusLock)`
  width: 100%;
  height: 100%;
`;

const StyledBaseModal = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const BaseModal = ({
  ariaLabel,
  autoFocus = false,
  beforeElementId,
  children,
  className,
  disableOutsideClick,
  id,
  onClose = () => {},
  role = 'dialog',
}: Props) => {
  const modalRef = useRef<HTMLDivElement>(null);
  useOnOutsideClick(modalRef, () => (disableOutsideClick ? {} : onClose()));

  return (
    <StyledPortal beforeElementId={beforeElementId} id={id}>
      {/* eslint-disable-next-line jsx-a11y/no-autofocus*/}
      <StyledFocusLock autoFocus={autoFocus} className={className} returnFocus>
        <StyledBaseModal
          aria-label={ariaLabel}
          aria-modal="true"
          role={role}
          tabIndex={-1}
        >
          {cloneElement(children, {
            ref: modalRef,
          })}
        </StyledBaseModal>
      </StyledFocusLock>
      <Overlay aria-hidden role="presentation" />
    </StyledPortal>
  );
};

export default BaseModal;
