import { AriaAttributes, ReactNode } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { RemoveScroll } from 'react-remove-scroll';
import { DisplayType } from '@shared/types/enums';
import { useBaseAnalytics } from '../../contexts/BaseAnalyticsContext';
import useEffectOnce from '../../hooks/useEffectOnce';
import { mqMin, shouldForwardProp } from '../../styles/base';
import BaseModal from './BaseModal';

export interface Props {
  ariaLabel: AriaAttributes['aria-label'];
  autoFocus?: boolean;
  beforeElementId?: string;
  children: ReactNode;
  className?: string;
  disableOutsideClick?: boolean;
  height?: string;
  id: string;
  onClose?: () => void;
  role?: 'dialog' | 'alertdialog';
  width?: string;
}

export const modalBreakpointDisplayType = DisplayType.Medium;

export const Container = styled(RemoveScroll, {
  shouldForwardProp: (prop) =>
    shouldForwardProp({ prop, excludeProps: ['height', 'width'] }),
})<{
  height: string;
  width: string;
}>(
  ({ height, theme, width }) => css`
    position: relative;
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 100%;
    flex-direction: column;
    background-color: ${theme.colours.surface.main};
    box-shadow: 0 4px 20px 1px rgb(0 0 0 / 50%);

    ${mqMin[modalBreakpointDisplayType]} {
      width: ${width};
      height: ${height};
      border-radius: ${theme.shape.borderRadiusM}px;
    }
  `,
);

const Modal = ({
  ariaLabel,
  autoFocus,
  beforeElementId,
  children,
  className,
  disableOutsideClick,
  height = '70vh',
  id,
  onClose,
  role,
  width = '600px',
}: Props) => {
  const { sendModalShownEvent } = useBaseAnalytics();
  useEffectOnce(() => sendModalShownEvent({ id }));

  return (
    <BaseModal
      ariaLabel={ariaLabel}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={autoFocus}
      beforeElementId={beforeElementId}
      className={className}
      disableOutsideClick={disableOutsideClick}
      id={id}
      onClose={onClose}
      role={role}
    >
      <Container height={height} width={width}>
        {children}
      </Container>
    </BaseModal>
  );
};

export default Modal;
