import { css } from '@emotion/react';
import styled from '@emotion/styled';
import useTranslation from 'next-translate/useTranslation';
import { ReactComponent as CrossIcon } from '../../../assets/icons/cross.svg';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/tiny-down-arrow.svg';
import { createTypography, mqMin } from '../../../styles/base';
import Button, { ButtonType } from '../../Button/Button';
import MediaQuery from '../../Responsive/MediaQuery';
import StepNavigation, {
  NavigationContainer,
} from '../../Steps/StepNavigation';
import Modal, { Container, modalBreakpointDisplayType } from '../Modal';
import PartnerModalFooter from './PartnerModalFooter';

export interface Props {
  ariaLabel?: string;
  autoFocus?: boolean;
  children?: React.ReactNode;
  className?: string;
  footerContent?: React.ReactNode;
  height?: string;
  id: string;
  onCloseModal?: () => void;
  role?: 'dialog' | 'alertdialog';
  showFooter?: boolean;
  title?: string | React.ReactElement;
  width?: string;
}

const StyledArrowIcon = styled(ArrowIcon)(
  ({ theme: { colours } }) => css`
    position: absolute;
    top: 50%;
    left: 4px;
    width: 18px;
    height: 18px;
    fill: ${colours.text.default};
    transform: translateY(-50%) rotate(90deg);
  `,
);

const StyledCrossIcon = styled(CrossIcon)<{
  color?: string;
}>(
  ({ color, theme: { colours } }) => css`
    width: 18px;
    height: 18px;
    fill: ${color ?? colours.text.on.interactive.secondary.default};
    stroke: ${color ?? colours.text.on.interactive.secondary.default};
  `,
);

const StyledTitle = styled.span(
  ({ theme: { spacings } }) => css`
    margin-left: ${spacings['24']}px;
  `,
);

export const PartnerModalBody = styled.div(
  ({ theme: { colours, spacings } }) => css`
    height: 100%;
    padding: ${spacings['24']}px;
    border-top: 1px solid ${colours.border.default};
    border-bottom: 1px solid ${colours.border.default};
    overflow-y: auto;
  `,
);

const Header = styled.div(({ theme: { colours, spacings, typography } }) => [
  createTypography(typography.heading05),
  css`
    position: relative;
    display: flex;
    justify-content: center;
    padding: ${spacings['24']}px ${spacings['16']}px;
    color: ${colours.text.default};
  `,
]);

const StyledModal = styled(Modal)(
  ({ theme: { shape, spacings } }) => css`
    display: flex;
    padding-top: ${spacings['16']}px;

    ${mqMin[modalBreakpointDisplayType]} {
      padding-top: 0;
      background: none;
    }

    ${Container} {
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;

      ${mqMin[modalBreakpointDisplayType]} {
        border-top-left-radius: ${shape.borderRadiusS}px;
        border-top-right-radius: ${shape.borderRadiusS}px;
      }
    }
  `,
);

const StyledStepNavigation = styled(StepNavigation)(
  ({ theme: { colours } }) => css`
    ${NavigationContainer} {
      background: ${colours.surface.main};
      color: ${colours.text.default};
    }
  `,
);

const PartnerModal = ({
  ariaLabel,
  autoFocus,
  children,
  className,
  footerContent,
  height,
  id,
  onCloseModal,
  role,
  showFooter = true,
  title,
  width,
}: Props) => {
  const { t } = useTranslation();

  return (
    <StyledModal
      ariaLabel={ariaLabel}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={autoFocus}
      className={className}
      height={height}
      id={id}
      onClose={onCloseModal}
      role={role}
      width={width}
    >
      {title && (
        <>
          <MediaQuery fromDisplay={modalBreakpointDisplayType}>
            <StyledStepNavigation
              rightContent={
                onCloseModal && (
                  <Button buttonType={ButtonType.RAW} onClick={onCloseModal}>
                    <StyledCrossIcon />
                    <span className="aux">{t('Close')}</span>
                  </Button>
                )
              }
              title={title}
            />
          </MediaQuery>

          <MediaQuery toDisplay={modalBreakpointDisplayType}>
            <Header>
              <Button buttonType={ButtonType.RAW} onClick={onCloseModal}>
                <StyledArrowIcon />
                <span className="aux">{t('Close')}</span>
              </Button>
              <StyledTitle>{title}</StyledTitle>
            </Header>
          </MediaQuery>
        </>
      )}

      <PartnerModalBody>{children}</PartnerModalBody>

      {showFooter &&
        (footerContent || (
          <PartnerModalFooter>
            <Button onClick={onCloseModal}>{t('Close')}</Button>
          </PartnerModalFooter>
        ))}
    </StyledModal>
  );
};

export default PartnerModal;
